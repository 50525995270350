<div class="row w-100 center"
>
<div class="col-md-7 col-sm-12 col-xs-12 left ">
  <div class="card">
    <div class="card-body">
      <div class="row"> <h4 class="header">Payment</h4>
          <div class="icons"> <img src="https://img.icons8.com/color/48/000000/visa.png" /> 
            <img src="https://img.icons8.com/color/48/000000/mastercard-logo.png" /> 
            <img src="https://img.icons8.com/color/48/000000/maestro.png" /> </div>
      </div>
      <form [formGroup]="form"> 
        <div class="form-group">
        <span>Cardholder's name:</span> 
        <input type="text"
        formControlName="username"
        class="form-control"
        placeholder="John Smith"> 
        <span>Card Number:</span> 
        <input 
        type="text"
        formControlName="pan"
        class="form-control"
        placeholder="0125 6780 4567 9909">
      </div>
      </form>
  </div>
  </div>
</div>
<div class="col-md-5 col-sm-12 col-xs-12 right">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Order Summary</h4>
      <p>2 items</p>
                    <div class="row item">
                        <div class="col-4 align-self-center"><img class="img-fluid" src="assets/boot_new.jpeg"></div>
                        <div class="col-8">
                            <div class="row"><b>$ 199.99</b></div>
                            <div class="row text-muted">Tan Hiking Boots</div>
                            <div class="row">Qty:1</div>
                        </div>
                    </div>
                    <div class="row item">
                        <div class="col-4 align-self-center"><img class="img-fluid" src="assets/socks.png"></div>
                        <div class="col-8">
                            <div class="row"><b>$ 19.99</b></div>
                            <div class="row text-muted">Hiking Socks Blue</div>
                            <div class="row">Qty:1</div>
                        </div>
                    </div>
                    <hr>
                    <div class="row lower">
                        <div class="col text-left">Subtotal</div>
                        <div class="col text-right">$ 219.98</div>
                    </div>
                    <div class="row lower">
                        <div class="col text-left">Delivery</div>
                        <div class="col text-right">Free</div>
                    </div>
                    <div class="row lower">
                        <div class="col text-left"><b>Total to pay</b></div>
                        <div class="col text-right"><b>$ 219.98</b></div>
                    </div>
                    <button [disabled]="loading || f.username.value == '' " class="btn btn-primary btn-block"  (click)="submit()"> <span
                      *ngIf="loading"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>Place order</button>
      </div>
  </div>
</div>
</div>

<ng-template #contentSuccess>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Authentication Result</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Success!
  </div>
</ng-template>

<ng-template #contentFailure>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Authentication Result</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Failure!
  </div>
</ng-template>

<ng-template #waitForAuth>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Second Factor Authentication</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="!done" class="modal-body">
    Please approve on your device
  </div>
  <div *ngIf="done" class="modal-body">
    Transaction Approved!
  </div>
</ng-template>

<ng-template #qr>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Second Factor Authentication</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="!done && !qrcode" class="modal-body">
    Please wait
  </div>
  <div *ngIf="!done && qrcode" class="modal-body">
    Please scan QR code and approve on device
    <qrcode [qrdata]="qrcode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
  <div *ngIf="done" class="modal-body">
    Transaction Approved!
  </div>
</ng-template>