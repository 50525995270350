import { Buffer } from 'buffer';
export class Util {
  static toBase64Url(
    notBase64Url: Array<any> | Uint8Array | ArrayBuffer | Buffer | string
  ): string {
    let temp = notBase64Url;

    if (Array.isArray(notBase64Url)) {
      temp = Uint8Array.from(temp as Array<number>);
    }
    if (temp instanceof Uint8Array) {
      temp = temp.buffer;
    }
    if (temp instanceof ArrayBuffer && !(temp instanceof Buffer)) {
      temp = Buffer.from(temp);
    }
    const base64 =
      temp instanceof Buffer ? temp.toString('base64') : (temp as string);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '');
  }

  static toArrayBuffer(
    notArrayBuffer: string | Buffer | Array<any> | Uint8Array
  ): ArrayBuffer {
    let temp = notArrayBuffer;

    if (typeof temp === 'string') {
      temp = Buffer.from(temp.replace(/-/g, '+').replace(/_/g, '/'), 'base64');
    }
    if (temp instanceof Buffer || Array.isArray(temp)) {
      temp = new Uint8Array(temp);
    }
    return temp.buffer;
  }

  static sanitizeString(original: string): string {
    return original.replace(/\s/g, '').toLowerCase();
  }
}
