import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FidoAuthParams,
  FidoAuthResult,
  SpcTransactionDetails
} from '../shared/models/fido-models';
import { SpcService } from '../shared/services/spc.service';
import { Util } from '../shared/services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  form: FormGroup;
  loading = false;
  submitted = false;
  done = false;
  returnUrl!: string;
  feedback!: string;
  amount = '219.98';
  qrcode = '';
  username: string;
  modalRef: BsModalRef;
  @ViewChild('contentSuccess')
  private contentSuccess: TemplateRef<any>;
  @ViewChild('contentFailure')
  private contentFailure: TemplateRef<any>;
  @ViewChild('waitForAuth')
  private waitForAuth: TemplateRef<any>;
  @ViewChild('qr')
  private qr: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private spcService: SpcService,
    private modalService: BsModalService
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      pan: ['', Validators.required]
    });
  }

  get f() {
    return this.form?.controls;
  }

  openModal(content: TemplateRef<any>) {
    this.modalRef = this.modalService.show(content, {
      class: 'modal-dialog-centered'
    });
  }

  async submit() {
    console.log(this.f.username.value, this.f.pan.value);
    this.submitted = true;
    this.loading = true;

    if (this.f.pan.value.length === 5) {
      this.openModal(this.qr);
      this.spcService.challengePaymentQR('3333').subscribe((code) => {
        console.log(code);
        this.qrcode = code.qr;
      });
      setTimeout(() => {
        this.done = true;
        this.loading = false;
      }, 22000);
      return;
    }
    this.openModal(this.waitForAuth);
    this.spcService.challengePayment(this.f.pan.value).subscribe(() => {
      console.log('done');
      this.loading = false;
      this.done = true;
    });
  }

  private async authenticate(): Promise<void> {
    const transactionDetails: SpcTransactionDetails = {
      merchantOrigin: 'https://shop.rnd.entersekt.com',
      total: {
        currency: 'USD',
        value: this.amount
      }
    };
    this.spcService.getAuthChallenge(this.f.pan.value).subscribe(
      async (params) => {
        console.log(params);
        const result = await this.paySPC(params);
        this.spcService
          .pay(this.f.pan.value, result, transactionDetails)
          .subscribe((success) => {
            this.loading = false;
            if (success) {
              this.openModal(this.contentSuccess);
            } else {
              this.openModal(this.contentFailure);
            }
          });
      },
      (err) => {
        console.error(err.message);
        console.log('Not signed up yet');
        this.openModal(this.contentFailure);
      }
    );
  }

  async paySPC(params: FidoAuthParams): Promise<FidoAuthResult> {
    try {
      let creds: ArrayBuffer[] = [];
      params.allowCredentials.forEach((cred) => {
        creds.push(Util.toArrayBuffer(cred.id));
      });
      const securePaymentConfirmationRequest = {
        action: 'authenticate',
        credentialIds: creds,
        networkData: Util.toArrayBuffer(params.challenge),
        timeout: params.timeout,
        fallbackUrl: ''
      };

      const request = new PaymentRequest(
        [
          {
            supportedMethods: 'secure-payment-confirmation',
            data: securePaymentConfirmationRequest
          }
        ],
        {
          total: {
            label: 'total',
            amount: { currency: 'USD', value: this.amount }
          }
        }
      );

      const response = await request.show();
      await response.complete('success');
      console.log(response);
      console.log(response.details.info.id);
      console.log(response.details.info.client_data_json);
      console.log(response.details.info.authenticator_data);
      console.log(response.details.authenticatorData);

      const result: FidoAuthResult = {
        keyHandle: response.details.info.id,
        clientData: Util.toBase64Url(response.details.info.client_data_json),
        authenticatorData: Util.toBase64Url(
          response.details.info.authenticator_data
        ),
        signature: Util.toBase64Url(response.details.signature)
      };
      return result;
    } catch (error) {
      this.loading = false;
      console.log(error);
      return Promise.reject(error.message);
    }
  }
}
