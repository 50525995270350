import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  FidoAuthParams,
  FidoAuthResult,
  SpcTransactionDetails
} from '../models/fido-models';
import { environment } from 'src/environments/environment';
import {
  AnswerBrowserInteractionRequest,
  BrowserInteractionAuthResponse,
  BrowserInteractionRequest,
  StartInteractionResult
} from '../models/channel-models';

@Injectable({
  providedIn: 'root'
})
export class SpcService {
  constructor(private http: HttpClient) {}

  challengePayment(pan: string): Observable<void> {
    return this.http.post<void>(
      `https://us-central1-strategics-f44fd.cloudfunctions.net/hackday/challengeForPayment`,
      { pan: pan, amount: '599.98' }
    );
  }

  challengePaymentQR(pan: string): Observable<any> {
    return this.http.post<any>(
      `https://us-central1-strategics-f44fd.cloudfunctions.net/hackday/challengeForPaymentQR`,
      { pan: pan, amount: '599.98' }
    );
  }

  initiateBrowserInteraction(data: BrowserInteractionRequest) {
    return this.http
      .post<BrowserInteractionAuthResponse>(
        `${environment.fidoServerURL}/initiateBrowserInteraction`,
        data,
        { withCredentials: true }
      )
      .toPromise();
  }

  answerBrowserInteraction(data: AnswerBrowserInteractionRequest) {
    return this.http
      .post(`${environment.fidoServerURL}/answerBrowserInteractionRq`, data, {
        withCredentials: true
      })
      .toPromise();
  }

  getAuthChallenge(username: string): Observable<FidoAuthParams> {
    return this.http.post<FidoAuthParams>(
      `${environment.serverURL}/challenge`,
      { username }
    );
  }

  pay(
    username: string,
    params: FidoAuthResult,
    transactionDetails: SpcTransactionDetails
  ): Observable<boolean> {
    return this.http
      .post<void>(`${environment.serverURL}/pay`, {
        username,
        params,
        transactionDetails
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.error('An error occurred:', err.status);
          return of(false);
        }),
        map(() => {
          return true;
        })
      );
  }
}
